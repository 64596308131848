import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';

const UserContext = createContext({});

export const UserProvider = props => {
  const { children } = props;
  const [userState, setUserState] = useState({});
  const [tokenState, setTokenState] = useState();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setTokenState(localStorage.getItem('token'));
    try {
      setUserState(JSON.parse(localStorage.getItem('user')));
    } catch(e) {

    }
    setInitialized(true)
  }, []);

  const setToken = (token) => {
    localStorage.setItem('token', token);
    setTokenState(token);
  }

  const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    setUserState(user)
  }

  const login = async ({ token, user }) => {
    setToken(token);
    setUser(user)
  }

  const logout = async (callbackFn) => {
    setToken();
    setUser();

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    if (callbackFn) {
      callbackFn();
    }
  }

  const contextValue = useMemo(() => ({
    user: userState,
    token: tokenState,
    setUser,
    setToken,
    login,
    logout,
    initialized,
  }), [
    userState,
    tokenState,
    setUser,
    setToken,
    login,
    logout,
    initialized,
  ]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
