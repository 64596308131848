export default function LabelField({ label, hints, error, register, type = 'text' }) {
  return (
    <div className="mb-4">
      <div className="text-white text-sm mb-2">{label}</div>
      <input
        className="placeholder-[#FEFEFE80] rounded-[100px] border border-[#FFFFFF80] bg-transparent w-full py-3 px-4"
        type={type}
        placeholder={hints}
        { ...register }
      />
      {
        error && (
          <div className="text-rose-300 text-sm mt-2">{error}</div>
        )
      }
    </div>
  )
}
