import {
  ApolloLink,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

let client;

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : '',
    }
  }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
  credentials: 'same-origin',
  fetch,
});

const apolloLink = ApolloLink.from([
  authLink,
  httpLink,
]);

export const createApolloClient = () => {
  if (!client) {
    client = new ApolloClient({
      link: apolloLink,
      cache: new InMemoryCache()
    });
  }

  return client;
 };
