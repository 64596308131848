import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from 'components/Button';
import { useDevice } from 'hooks/useDevice';
import { useCart } from 'hooks/useCart';
import getDBIdFromGraphqlId from 'utils/getDBIdFromGraphqlId';
import { CREATE_CHECKOUT } from 'queries/shop';

export default function Checkout() {
  const { t } = useTranslation();
  const { device } = useDevice();
  const { cart } = useCart();
  const [createCheckout, { loading }] = useMutation(CREATE_CHECKOUT);
  const [error, setError] = useState();

  const handleSubmit = async () => {
    try {
      const { data } = await createCheckout({
        variables: {
          productId: getDBIdFromGraphqlId(cart?.id),
          serialNumber: device,
        }
      });

      if (!data.createCheckout?.url) {
        throw new Error('No checkout URL returned')
      }

      window.location.href = data.createCheckout.url;
    } catch(e) {
      setError(t('checkout.error'))
    }
  }

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <header className="mb-6 grid grid-cols-3">
        <Link to="/"><img src="/images/arrow-left.svg" alt="Back" /></Link>
        <h1 className="text-center">{t('checkout.title')}</h1>
      </header>
      <div className="flex-1">
        <div className="mb-8">
          <div className="text-center text-xl font-bold">
            {t('checkout.text1')}
          </div>
        </div>
        <div className="mb-4 bg-[#F2F2F299] text-[#FEFEFE] rounded-md px-6 py-4">
          <span className="mr-2">{t('checkout.text2')}</span>
          <span>{device}</span>
        </div>
        <div className="mb-8 bg-[#4c4e53] text-[#FEFEFE] rounded-md">
          <div className="bg-[#F2F2F299] text-[#FEFEFE] rounded-md px-6 py-4">
            {cart?.name}
          </div>
          <div className="px-6 py-4" dangerouslySetInnerHTML={{ __html: cart?.description }} />
        </div>
        {
          error && (
            <div className="text-rose-300 text-sm text-center mb-4">{error}</div>
          )
        }
        <Button
          loading={loading}
          onClick={handleSubmit}
          background="#56CCF2"
          color="#FEFEFE"
        >
          {t('checkout.text3')}
        </Button>
      </div>
    </div>
  )
}
