import {
  Navigate,
  Outlet,
} from 'react-router-dom';

import Loading from './Loading';
import { useUser } from 'hooks/useUser';

export default function ProtectedRoute() {
  const { token, initialized, user } = useUser();

  if (!initialized) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loading size="xl" />
      </div>
    )
  }

  if (initialized && !user) {
    return <Navigate to="/signin" />;
  }

  if (user?.role === 'AGENT') {
    return <Navigate to="/acknowledgement-receipt" />;
  }

  return <Outlet />;
}
