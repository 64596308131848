import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useUser } from 'hooks/useUser';
import Button from 'components/Button';
import ActivatedPlanCard from 'components/ActivatedPlanCard';


export default function DistributorConfirmation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { subscription } = location.state;

  useEffect(() => {
    if (user?.role !== 'AGENT') {
      navigate('/serial-number');
    }
  }, [user]);

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1 flex flex-col">
        <header className="mt-6 mb-8 flex justify-center">
          <img src="/images/logo.png" alt="KEX Karaoke logo" />
        </header>
        <div className="flex-1">
          <h1 className="text-center mb-4">{t('distributor_confirmation.text1')}</h1>
          <p className="text-center text-[#B0B0B3] mb-4">{t('distributor_confirmation.text2')}</p>
          <ActivatedPlanCard
            product={subscription.product}
            serialNumber={subscription.device.serialNumber}
            subscription={subscription}
            showExpiryDate
          />
          <p className="text-center text-[#B0B0B3]">
            <span className="text-white mr-2">{t('distributor_confirmation.text3')}</span>
            <span>{t('distributor_confirmation.text4')}</span>
          </p>
        </div>
      </div>
      <div>
        <div>
          <Button
            onClick={() => {
              window.location.href = 'aex://main';
            }}
            background="#56CCF2"
            color="#FEFEFE"
          >
            {t('payment_success.text3')}
          </Button>
        </div>
      </div>
    </div>
  )
}
