import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { VERIFY_TOKEN } from 'queries/account';
import { useUser } from 'hooks/useUser';

export default function ValidateAuthToken() {
  const { logout } = useUser();
  const [verifyToken] = useMutation(VERIFY_TOKEN);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      return;
    }

    verifyToken({
      variables: {
        token,
      }
    }).then(result => {
      if (!result.data.verifyToken.payload) {
        logout();
      }
    }).catch(() => {
      logout();
    });
  }, [])

  return null;
}
