import { use } from 'react';

import ActivatedPlanCard from 'components/ActivatedPlanCard';
import { useDevice } from 'hooks/useDevice';
import { ACTIVE_SUBSCRIPTION } from 'queries/subscription';
import { createApolloClient } from 'apollo';

const fetchActiveSubscription = async (serialNumber) => {
  const client = await createApolloClient();
  return client.query({
    query: ACTIVE_SUBSCRIPTION,
    variables: {
      serialNumber,
    },
    fetchPolicy: 'network-only',
  })
}

export default function ActiveSubscription() {
  const { device } = useDevice();
  const result = use(fetchActiveSubscription(device))
  const subscription = result?.data?.activeSubscription;

  if (!subscription || !subscription?.product?.price) {
    return null;
  }

  return (
    <ActivatedPlanCard
      serialNumber={subscription.device.serialNumber}
      product={subscription?.product}
      subscription={subscription}
      showExpiryDate={true}
    />
  )
}
