import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';

import { useDevice } from 'hooks/useDevice';
import { CHECK_SERIAL_NUMBER } from 'queries/subscription';

export function AddDevice() {
  const [isOpen, setIsOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const { t } = useTranslation();
  const [serialNumber, setSerialNumber] = useState('');
  const [checkSerialNumber] = useLazyQuery(CHECK_SERIAL_NUMBER);

  const { setDevice } = useDevice();

  const handleSubmit = async () => {
    try {
      const result = await checkSerialNumber({
        variables: {
          serialNumber,
        }
      });

      setIsOpen(false);

      if (!result?.data?.checkSerialNumber) {
        setIsErrorOpen(true);
        return;
      }

      setDevice(serialNumber);
      setSerialNumber('');

    } catch(e) {
      console.log(e)
    } finally {

    }
  }

  return (
    <div className="mb-6">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div" className="fixed inset-0 bg-black bg-opacity-70 opacity-100 z-50 m-4"
          onClose={() => setIsOpen(false)}
        >
          <div className="flex h-full items-center justify-center text-[#333]">
            <Dialog.Panel className="w-full max-w-sm rounded bg-white flex flex-col items-center justify-center px-6 py-6">
              <Dialog.Title>{t('serial_number.add_device_title')}</Dialog.Title>
              <div className="my-4 w-full">
                <input
                  autoFocus
                  value={serialNumber}
                  onChange={e => setSerialNumber(e.target.value)}
                  className="w-full border rounded-md px-4 py-2"
                  placeholder={t('serial_number.add_device_placeholder')}
                />
              </div>
              <div className="flex justify-between w-full">
                <button onClick={() => setIsOpen(false)}>{t('common.cancel')}</button>
                <button onClick={handleSubmit}>{t('common.confirm')}</button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isErrorOpen} as={Fragment}>
        <Dialog
          as="div" className="fixed inset-0 bg-black bg-opacity-70 opacity-100 z-[99] m-4"
          onClose={() => setIsErrorOpen(false)}
        >
          <div className="flex h-full items-center justify-center text-[#333]">
            <Dialog.Panel className="w-full max-w-sm rounded bg-white flex flex-col items-center justify-center px-6 py-6">
              <Dialog.Title>{t('serial_number.add_device_title_error')}</Dialog.Title>
              <div className="flex justify-center w-full mt-2 text-sm">
                <button onClick={() => setIsErrorOpen(false)}>{t('common.close')}</button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition>
      <div
        className="mt-24 text-center"
      >
        {t('serial_number.text3')}
        <a
          className="ml-1 cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          {t('serial_number.text6')}
        </a>
      </div>
    </div>
  )
}
