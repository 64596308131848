import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from 'components/Button';
import ActivatedPlanCard from 'components/ActivatedPlanCard';
import { CANCEL_SUBSCRIPTION } from 'queries/subscription';
import getDBIdFromGraphqlId from 'utils/getDBIdFromGraphqlId';

export default function CancelSubscriptions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { subscription } = state;
  const [cancelSubscription, { loading }] = useMutation(CANCEL_SUBSCRIPTION)

  const handleSubmit = () => {
    cancelSubscription({
      variables: {
        subscriptionId: getDBIdFromGraphqlId(subscription.id),
      }
    }).then(() => {
      navigate('/my-subscriptions');
    })
  }

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <header className="mb-6 flex">
        <Link className="w-8" to="/my-subscriptions">
          <img src="/images/arrow-left.svg" alt="Back" />
        </Link>
        <h1 className="flex-1 text-center">{t('cancel_subscription.title')}</h1>
        <div className="w-8"></div>
      </header>
      <div className="flex-1 flex flex-col justify-center">
        <ActivatedPlanCard
          product={subscription.product}
          serialNumber={subscription.device.serialNumber}
          subscription={subscription}
          showCancel={false}
        />
      </div>
      <div>
        <div className="mb-4">
          <Button
            onClick={() => navigate('/my-subscriptions')}
          >
            {t('cancel_subscription.dismiss')}
          </Button>
        </div>
        <div>
          <Button
            onClick={handleSubmit}
            loading={loading}
            className="bg-transparent hover:bg-rose-400 duration-300"
          >
            {t('cancel_subscription.confirm')}
          </Button>
        </div>
      </div>
    </div>
  )
}
