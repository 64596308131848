import { gql } from '@apollo/client';

export const GET_MY_SUBSCRIPTIONS = gql`
  query {
    mySubscriptions {
      id
      startDate
      endDate
      status
      device {
        serialNumber
      }
      product {
        id
        name
        periodType
        price
      }
    }
  }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation(
    $subscriptionId: ID!
  ) {
    cancelSubscription(
      subscriptionId: $subscriptionId
    ) {
      success
    }
  }
`


export const ACTIVE_SUBSCRIPTION = gql`
  query(
    $serialNumber: String!
  ) {
    activeSubscription(
      serialNumber: $serialNumber
    ) {
      id
      startDate
      endDate
      status
      device {
        serialNumber
      }
      product {
        id
        name
        periodType
        price
        distributorTrial
      }
      eligibleForUpgrade
      eligibleForDowngrade
    }
  }
`

export const CHANGE_SUBSCRIPTION = gql`
  mutation(
    $subscriptionId: ID!
    $productId: ID!
  ) {
    changeSubscription(
      subscriptionId: $subscriptionId
      productId: $productId
    ) {
      success
      redirectUrl
    }
  }
`


export const CHECK_SERIAL_NUMBER = gql`
  query(
    $serialNumber: String!
  ) {
    checkSerialNumber(
        serialNumber: $serialNumber
    )
  }
`
