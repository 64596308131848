import { forwardRef } from 'react';
import classNames from 'classnames'

import Loading from 'components/Loading';

const Button = forwardRef(({ children, onClick, className, loading = false, background = '#4F4F4F', color = '#E1E1E2' }, ref) => {
  return (
    <div
      ref={ref}
      onClick={onClick}
      className={classNames(className, 'py-3 text-center rounded-[100px] cursor-pointer', `bg-[${background}]`, `text-[${color}]`)}
    >
      {
        loading
          ? <Loading />
          : children
      }
    </div>
  )
})

export default Button;
