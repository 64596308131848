import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import LabelField from 'components/LabelField';
import Button from 'components/Button';
import SendPasswordResetSuccess from 'components/SendPasswordResetSuccess';
import { FORGOT_PASSWORD } from 'queries/account';

export default function ForgotPassword() {
  const [isCompleted, setIsCompleted] = useState(false);
  const { t } = useTranslation();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);
  const { register, handleSubmit, setError, clearErrors, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      email: '',
    }
  });

  const onSubmit = async (values) => {
    try {
      const result = await forgotPassword({
        variables: {
          email: values.email,
        }
      });

      if (!result.data?.sendPasswordResetEmail?.success) {
        throw new Error();
      }

      setIsCompleted(true);
    } catch(e) {
      console.log(e)
      setError('api', { type: 'custom', message: 'System error'});
    }
  }

  if (isCompleted) {
    return <SendPasswordResetSuccess />
  }

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1">
        <div className="mt-6 mb-8">
          <div className="flex justify-center mb-10">
            <img src="/images/logo.png" alt="KEX Karaoke logo" />
          </div>
          <LabelField
            label={t('common.email')}
            type="email"
            hints={t('common.email_hints')}
            error={errors.email?.message}
            register={register('email', { required: t('common.required') })}
          />
        </div>
        {
          errors.api && (
            <div className="text-rose-300 text-sm mb-4">{errors.api.message}</div>
          )
        }
        <div className="mb-4">
          <Button
            loading={isSubmitting || loading}
            background="#2D9CDB"
            color="#FEFEFE"
            onClick={() => {
              clearErrors('api');
              handleSubmit(onSubmit)()
            }}
          >
            {t('forgot_password.text2')}
          </Button>
        </div>
      </div>
    </div>
  )
}
