import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from 'components/Loading';
import { useUser } from 'hooks/useUser';

export default function SignOut() {
  const { logout, user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    logout(() => {
      navigate('/')
    });
  }, []);

  return (
    <div className="min-h-screen flex justify-center items-center">
      <Loading />
    </div>
  )
}
