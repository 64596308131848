import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query(
    $serialNumber: String!
  ) {
    products(
      serialNumber: $serialNumber
    ) {
      edges {
        node {
          id
          name
          description
          periodType
          createdAt
          updatedAt
        }
      }
    }
  }
`;
