import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import { useCart } from 'hooks/useCart';
import { CHANGE_SUBSCRIPTION } from 'queries/subscription';
import getDBIdFromGraphqlId from 'utils/getDBIdFromGraphqlId';

export default function Form({ subscription }) {
  const { t } = useTranslation();
  const { cart } = useCart();
  const navigate = useNavigate();
  const [changeSubscription, { loading }] = useMutation(CHANGE_SUBSCRIPTION);

  const handleSubmit = async () => {
    const result = await changeSubscription({
      variables: {
        subscriptionId: getDBIdFromGraphqlId(subscription.id),
        productId: getDBIdFromGraphqlId(cart.id),
      }
    });

    if (result.data.changeSubscription.redirectUrl) {
      window.location.href = result.data.changeSubscription.redirectUrl;
    } else if (result.data.changeSubscription.success) {
      navigate('/my-subscriptions')
    }
  }

  return (
    <Button
      loading={loading}
      onClick={handleSubmit}
      background="#56CCF2"
      color="#FEFEFE"
    >
      {
        subscription.eligibleForUpgrade
          ? t('current_plan.text7')
          : t('current_plan.text8')
        }
    </Button>
  )
}
