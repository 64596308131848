import { gql } from '@apollo/client';

export const REGISTER = gql`
  mutation(
    $displayName: String!
    $email: String!
    $password: String!
  ) {
    register(
      displayName: $displayName
      username: $email
      email: $email
      password: $password
    ) {
      token
      errors
      user {
        id
        displayName
        email
        role
      }
    }
  }
`;


export const TOKEN_AUTH = gql`
  mutation(
    $username: String!
    $password: String!
  ) {
    tokenAuth(
      username: $username
      password: $password
    ) {
      token
      user {
        id
        displayName
        email
        role
      }
    }
  }
`


export const GOOGLE_OAUTH = gql`
  mutation(
    $accessToken: String!
  ) {
    googleOauth(
      accessToken: $accessToken
    ) {
      token
      user {
        id
        displayName
        email
        role
      }
    }
  }
`


export const FORGOT_PASSWORD = gql`
  mutation(
    $email: String!
  ) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation(
    $password: String!
    $token: String!
  ) {
    passwordReset(
      newPassword: $password
      token: $token
    ) {
      success
      errors
    }
  }
`


export const VERIFY_TOKEN = gql`
  mutation(
    $token: String!
  ) {
    verifyToken(
      token: $token
    ) {
      payload
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation(
    $newPassword: String!
  ) {
    passwordChange(
      newPassword: $newPassword
    ) {
      token
      success
      errors
    }
  }
`
