import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="max-w-xl min-h-screen mx-auto flex flex-col justify-center text-center">
          <div>
            <img className="mx-auto mb-8" src="/images/logo.png" alt="logo" />
            <h1 className="mb-2">Sorry, system error</h1>
            <p>Please contact cs for assistance, <a href="https://api.whatsapp.com/send?phone=53018544">+852 5301 8544</a></p>
          </div>
        </div>
      )
    }

    return this.props.children;
  }
}
