import { Base64 } from 'js-base64';

export default function getDBIdFromGraphqlId(
  graphqlId,
  schema,
) {
  // This is temporary solution, we will use slugs in the future

  if (!graphqlId) {
    return null;
  }

  const rawId = Base64.decode(graphqlId);
  const regexp = /(\w+):(\d+)/;
  const [, expectedSchema, id] = regexp.exec(rawId);
  if (schema && schema !== expectedSchema) {
    throw new Error('Schema is not correct');
  }
  return parseInt(id, 10);
};
