import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import LabelField from 'components/LabelField';
import Button from 'components/Button';
import { CHANGE_PASSWORD } from 'queries/account';
import { useUser } from 'hooks/useUser';

export default function ChangePassword() {
  const [isCompleted, setIsCompleted] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToken } = useUser();
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD);
  const { register, handleSubmit, setError, clearErrors, watch, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      email: '',
    }
  });

  const onSubmit = async (values) => {
    try {
      const result = await changePassword({
        variables: {
          newPassword: values.password,
        }
      });

      if (!result.data?.passwordChange?.success) {
        throw new Error();
      }

      setToken(result.data.passwordChange.token)

      setIsCompleted(true);
    } catch(e) {
      console.log(e)
      setError('api', { type: 'custom', message: 'System error'});
    }
  }

  if (isCompleted) {
    return (
      <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1">
        <div className="mt-6 mb-8">
          <div className="flex justify-center mb-10">
            <img src="/images/logo.png" alt="KEX Karaoke logo" />
          </div>
          <div>
            <div className="text-center text-2xl font-bold mb-4">{t('change_password.text2')}</div>
            <Button
              onClick={() => {
                navigate('/');
              }}
            >
              {t('change_password.text3')}
            </Button>
          </div>
        </div>
      </div>
    </div>
    )
  }

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1">
        <div className="mt-6 mb-8">
          <div className="flex justify-center mb-10">
            <img src="/images/logo.png" alt="KEX Karaoke logo" />
          </div>
          <LabelField
            label={t('common.password')}
            type="password"
            hints={t('common.password_hints')}
            error={errors.password?.message}
            register={register('password', { required: t('common.required') })}
          />
          <LabelField
            label={t('common.confirm_password')}
            type="password"
            hints={t('common.confirm_password_hints')}
            error={errors.confirmPassword?.message}
            register={register('confirmPassword', {
              required: t('common.confirm_password_required'),
              validate: (val) => {
                if (watch('password') !== val) {
                  return t('common.confirm_password_invalid')
                }
              },
            }) }
          />
        </div>
        {
          errors.api && (
            <div className="text-rose-300 text-sm mb-4">{errors.api.message}</div>
          )
        }
        <div className="mb-4">
          <Button
            loading={isSubmitting || loading}
            background="#2D9CDB"
            color="#FEFEFE"
            onClick={() => {
              clearErrors('api');
              handleSubmit(onSubmit)()
            }}
          >
            {t('change_password.text1')}
          </Button>
        </div>
      </div>
    </div>
  )
}
