import { Suspense, use } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
import Button from 'components/Button';
import ActivatedPlanCard from 'components/ActivatedPlanCard';
import { useDevice } from 'hooks/useDevice';
import { ACTIVE_SUBSCRIPTION } from 'queries/subscription';
import { createApolloClient } from 'apollo';

import Form from './components/Form';

const fetchActiveSubscription = async (serialNumber) => {
  const client = await createApolloClient();
  return client.query({
    query: ACTIVE_SUBSCRIPTION,
    variables: {
      serialNumber,
    },
    fetchPolicy: 'network-only',
  })
}

export default function CurrentPlan() {
  const { t } = useTranslation();
  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <header className="mb-6 flex">
        <Link to="/" className="w-8"><img src="/images/arrow-left.svg" alt="Back" /></Link>
        <h1 className="flex-1 text-center">{t('current_plan.title')}</h1>
        <div className="w-8"></div>
      </header>
      <Suspense fallback={<div className="flex flex-1 items-center justify-center"><Loading size="xl" /></div>}>
        <CurrentPlanContent />
      </Suspense>
    </div>
  )
}

function CurrentPlanContent() {
  const { device } = useDevice();
  const { t } = useTranslation();
  const result = use(fetchActiveSubscription(device))
  const navigate = useNavigate();
  const subscription = result?.data?.activeSubscription;

  if (!subscription || subscription.product.distributorTrial) {
    return <Navigate to="/checkout" />
  }

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1">
        <div className="mb-8">
          <div className="text-center text-xl font-bold">
            {t('current_plan.text1')}
          </div>
        </div>
        <div className="mb-4 bg-[#F2F2F299] text-[#FEFEFE] rounded-md px-6 py-4">
          <div className="mr-2">{t('current_plan.text2')}</div>
          <span>{device}</span>
        </div>
        <ActivatedPlanCard
          product={subscription?.product}
          subscription={subscription}
          showExpiryDate={true}
        />
      </div>
      {
        (subscription.eligibleForUpgrade || subscription.eligibleForDowngrade) && (
          <Form subscription={subscription} />
        )
      }
      {
        !(subscription.eligibleForUpgrade && subscription.eligibleForDowngrade) && (
          <Button
            className="mt-4"
            onClick={() => {
              navigate(-1)
            }}
          >
            {t('current_plan.text6')}
          </Button>
        )
      }
    </div>
  )
}
