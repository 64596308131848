import { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
// import Button from 'components/Button';
import SubscriptionPlanList from './components/SubscriptionPlanList';

export default function MySubscriptions() {
  const { t } = useTranslation();

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <header className="mb-6 flex">
        <Link className="w-8" to="/">
          <img src="/images/arrow-left.svg" alt="Back" />
        </Link>
        <h1 className="flex-1 text-center">{t('my_subscriptions.title')}</h1>
        <div className="w-8"></div>
      </header>
      <div className="flex-1 flex flex-col justify-center">
        <Suspense fallback={<Loading size="xl" />}>
          <SubscriptionPlanList />
        </Suspense>
      </div>
      {/* <div>
        <Button
        >
          {t('my_subscriptions.text2')}
        </Button>
      </div> */}
    </div>
  )
}
