import { use } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ActivatedPlanCard from 'components/ActivatedPlanCard';
import { GET_ORDER } from 'queries/shop';
import { createApolloClient } from 'apollo';

const fetchSubscription = async (sessionId) => {
  const client = await createApolloClient();
  return client.query({
    query: GET_ORDER,
    variables: {
      sessionId,
    }
  })
}

export default function ActivatedPlan() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const { t } = useTranslation();
  const result = use(fetchSubscription(sessionId));
  const { order } = result.data;

  if (!order) {
    throw new Error();
  }

  return (
    <>
      <div className="mb-8">
        <div className="text-center">
          <h1 className="mb-4">
            {order.status !== 'PAID' ? t('payment_success.title_2') : t('payment_success.title')}
          </h1>
          <p className="text-[#B0B0B3]">
            {order.status !== 'PAID' ? t('payment_success.text4') : t('payment_success.text1')}
          </p>
        </div>
      </div>
      <ActivatedPlanCard
        product={order.product}
        serialNumber={order.device.serialNumber}
        subscription={order.subscription}
      />
    </>
  )
}
