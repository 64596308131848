import { useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

export default function ActivatedPlanCard({ product, serialNumber, subscription, deafultIsOpen = true, showCollapse = false, showCancel = false, showExpiryDate = false }) {
  const [isOpen, setIsOpen] = useState(deafultIsOpen);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="mb-8 bg-[#4c4e53] text-[#FEFEFE] rounded-md">
      <div className="bg-[#F2F2F299] text-[#FEFEFE] rounded-md px-6 py-4 flex justify-between">
        <div>
          <div className="mr-2">{t('common.activated_device')}:</div>
          <span>{serialNumber}</span>
        </div>
        {
          showCollapse && (
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="cursor-pointer text-xl font-bold"
            >
              &#43;
            </div>
          )
        }
      </div>
      <AnimatePresence initial={false}>
        {
          isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 }
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <div className="px-6 py-4 [&>div]:mb-2">
                <div>
                  <span className="mr-2 text-white/70">{t('common.subscription_fee')}:</span>
                  <span>HKD${product.price} {t(`common.per_${product.periodType.toLowerCase()}`)}</span>
                </div>
                <div>
                  <span className="mr-2 text-white/70">{t('common.activated_plan')}:</span>
                  <span>{product.name}</span>
                </div>
                {
                  !!subscription?.startDate && (
                    <div>
                      <span className="mr-2 text-white/70">{t('common.start_from')}:</span>
                      <span>{dayjs(subscription?.startDate).format('MMM DD, YYYY')}</span>
                    </div>
                  )
                }
                {
                  !!subscription?.endDate && (
                    <div>
                      <span className="mr-2 text-white/70">{ showExpiryDate ? t('common.expiry_date') : t('common.next_payment')}:</span>
                      <span>{dayjs(subscription?.endDate).format('MMM DD, YYYY')}</span>
                    </div>
                  )
                }
                </div>
              {
                showCancel && (
                  <div className="px-6 py-3">
                    <div
                      onClick={() => navigate('/cancel-subscription', { state: { subscription } })}
                      className="underline hover:no-underline cursor-pointer inline"
                    >
                      {t('my_subscriptions.text3')}
                    </div>
                  </div>
                )
              }
            </motion.section>
          )
        }
      </AnimatePresence>
    </div>
  )
}
