import { use } from 'react';
import { useTranslation } from 'react-i18next';

import ActivatedPlanCard from 'components/ActivatedPlanCard';
import { GET_MY_SUBSCRIPTIONS } from 'queries/subscription';
import { createApolloClient } from 'apollo';

import SubscriptionPlanHistory from './components/SubscriptionPlanHistory';

const fetchSubscriptions = async (sessionId) => {
  const client = await createApolloClient();
  return client.query({
    query: GET_MY_SUBSCRIPTIONS,
    variables: {
      sessionId,
    },
    fetchPolicy: 'network-only',
  })
}

export default function SubscriptionPlanList() {
  const { t } = useTranslation();
  const result = use(fetchSubscriptions());
  const { mySubscriptions } = result.data;

  const activeSubscriptions = mySubscriptions.filter(subscription => subscription.status === 'ACTIVE');
  const inactiveSubscriptions = mySubscriptions.filter(subscription => subscription.status !== 'ACTIVE');

  return (
    <div>
      {
        !activeSubscriptions.length && (
          <div className="text-center">{t('my_subscriptions.no_active')}</div>
        )
      }
      {
        activeSubscriptions.map(subscription => (
          <ActivatedPlanCard
            key={subscription.id}
            product={subscription.product}
            serialNumber={subscription.device.serialNumber}
            subscription={subscription}
            showCollapse={true}
            showCancel={true}
          />
        ))
      }
      <SubscriptionPlanHistory subscriptions={inactiveSubscriptions} />
    </div>
  )
}
