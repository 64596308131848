import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import LabelField from 'components/LabelField';
import Button from 'components/Button';
import { REGISTER } from 'queries/account';
import { useUser } from 'hooks/useUser';

export default function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useUser();
  const [signUp, { loading }] = useMutation(REGISTER);
  const { register, handleSubmit, watch, setError, clearErrors, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      displayName: '',
      email: '',
      password: '',
      confirmPassword: '',
    }
  });

  const onSubmit = async (values) => {
    try {
      const result = await signUp({
        variables: {
          displayName: values.displayName,
          email: values.email,
          password: values.password,
        }
      });

      if (result.data?.register?.errors?.nonFieldErrors[0][0].code === 'unique_email') {
        setError('api', { type: 'custom', message: 'This Email has been registered, please login.' });
        return;
      }

      if (!result.data.register.token) {
        throw new Error('Register failed');
      }

      const { token, user } = result.data.register;
      await login({ token, user });

      navigate('/serial-number');
    } catch(e) {
      console.log(e)
      setError('api', { type: 'custom', message: 'System error'});
    }
  }

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1">
        <header className="mb-6">
          <Link to="/"><img src="/images/arrow-left.svg" alt="Back" /></Link>
        </header>
        <div className="mb-8">
          <div className="flex justify-center mb-10">
            <img src="/images/logo.png" alt="KEX Karaoke logo" />
          </div>
          <LabelField
            label={t('common.display_name')}
            hints={t('common.display_name_hints')}
            error={errors.displayName?.message}
            register={register('displayName', { required: t('common.required') })}
          />
          <LabelField
            label={t('common.email')}
            type="email"
            hints={t('common.email_hints')}
            error={errors.email?.message}
            register={register('email', {
              required: t('common.email_required'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('common.email_invalid'),
              }
            }) }
          />
          <LabelField
            label={t('common.password')}
            type="password"
            hints={t('common.password_hints2')}
            error={errors.password?.message}
            register={register('password', { required: t('common.required'), minLength: {
              value: 8,
              message: t('common.password_min_length'),
            } }) }
          />
          <LabelField
            label={t('common.confirm_password')}
            type="password"
            hints={t('common.confirm_password_hints')}
            error={errors.confirmPassword?.message}
            register={register('confirmPassword', {
              required: t('common.confirm_password_required'),
              validate: (val) => {
                if (watch('password') !== val) {
                  return t('common.confirm_password_invalid')
                }
              },
            }) }
          />
        </div>
        {
          errors.api && (
            <div className="text-rose-300 text-sm mb-4">{errors.api.message}</div>
          )
        }
        <div className="mb-4">
          <Button
            loading={isSubmitting || loading}
            background="#2D9CDB"
            color="#FEFEFE"
            onClick={() => {
              clearErrors('api');
              handleSubmit(onSubmit)()
            }}
          >
            {t('signup.text1')}
          </Button>
        </div>
        <div className="text-center">
          <span className="mr-2">{t('signup.text2')}</span>
          <Link to="/signin">{t('signup.text3')}</Link>
        </div>
      </div>
      <div>
        <Button
          background="#FFFFFF"
          color="#333333"
        >
          <div className="flex items-center justify-center">
            <img className="mr-2" src="/images/google.png" alt="Google logo" />
            {t('common.google')}
          </div>
        </Button>
      </div>
    </div>
  )
}
