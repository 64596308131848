import { gql } from '@apollo/client';

export const CREATE_CHECKOUT = gql`
  mutation(
    $productId: ID!
    $serialNumber: String!
  ) {
    createCheckout(
      productId: $productId,
      serialNumber: $serialNumber
    ) {
      url
    }
  }
`

export const GET_ORDER = gql`
  query(
    $sessionId: String!
  ) {
    order(
      sessionId: $sessionId
    ) {
      id
      device {
        serialNumber
      }
      product {
        id
        name
        periodType
        price
      }
      subscription {
        id
        startDate
        endDate
        status
      }
      status
    }
  }
`
