import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useClickAway } from '@uidotdev/usehooks';

import Button from 'components/Button';

export default function SettingButton() {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ref = useClickAway(() => {
    setShowPopup(false);
  });

  return (
    <Button
      ref={ref}
      className="relative flex p-[8px] h-[32px] w-[32px] items-center justify-center"
      onClick={() => setShowPopup(!showPopup)}
    >
      <img src="/images/ellipsis.png" alt="Setting" />
      {
        showPopup && (
          <div
            className="absolute right-0 top-full bg-white whitespace-nowrap mt-2 px-4 py-2 rounded text-black/70 flex flex-col items-start"
          >
            <div
              className="py-2 border-b"
              onClick={() => {
                navigate('/change-password');
              }}
            >
              {t('common.change_password')}
            </div>
            <div
              className="py-2"
              onClick={() => {
                navigate('/signout');
              }}
            >
              {t('common.signout')}
            </div>
          </div>
        )
      }
    </Button>
  )
}
