import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';

const DeviceContext = createContext({});

export const DeviceProvider = props => {
  const { children } = props;
  const [device, setDevice] = useState();

  const contextValue = useMemo(() => ({
    device,
    setDevice,
  }), [
    device,
  ]);

  return (
    <DeviceContext.Provider value={contextValue}>
      {children}
    </DeviceContext.Provider>
  )
}

export const useDevice = () => useContext(DeviceContext);
