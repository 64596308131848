import { useEffect, useRef, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation, useLazyQuery } from '@apollo/client';
import SignatureCanvas from 'react-signature-canvas'
import dayjs from 'dayjs';
import { Dialog, Transition } from '@headlessui/react'

import LabelField from 'components/LabelField';
import Button from 'components/Button';
import { useUser } from 'hooks/useUser';
import { CREATE_ACKNOWLEDGEMENT_RECEIPT } from 'queries/distributor';
import { CHECK_SERIAL_NUMBER } from 'queries/subscription';

export default function AcknowledgementReceipt() {
  const { user } = useUser();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const [createAcknowledgementReceipt, { loading }] = useMutation(CREATE_ACKNOWLEDGEMENT_RECEIPT)
  const { register, handleSubmit, setError, clearErrors, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      distributionCompany: '',
      invoiceNumber: '',
      dateOfReceipt: dayjs().format('YYYY-MM-DD'),
      serialNumber: '',
    }
  });
  const [checkSerialNumber] = useLazyQuery(CHECK_SERIAL_NUMBER);
  const [isErrorOpen, setIsErrorOpen] = useState(false);

  useEffect(() => {
    if (user?.role !== 'AGENT') {
      navigate('/serial-number');
    }
  }, [user]);

  const convertBase64ToFile = function (base64File) {
    var arr = base64File.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File(
      [u8arr],
      `${Date.now()}.png`,
      { type: mime }
    );

    return file;
  };

  const onSubmit = async (values) => {
    try {
      const result = await checkSerialNumber({
        variables: {
          serialNumber: values.serialNumber,
        }
      });

      if (!result?.data?.checkSerialNumber) {
        setIsErrorOpen(true);
        return;
      }
    } catch(e) {
      console.log(e);
    }

    try {
      const base64Image = canvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      const signature = convertBase64ToFile(base64Image);

      const { data } = await createAcknowledgementReceipt({
        variables: {
          distributionCompany: values.distributionCompany,
          invoiceNumber: values.invoiceNumber,
          dateOfReceipt: values.dateOfReceipt,
          serialNumber: values.serialNumber,
          signature: signature,
        }
      });

      if (data.createAcknowledgementReceipt?.error) {
        setError('api', { type: 'custom', message: data.createAcknowledgementReceipt?.error });
        return;
      } else if (!data.createAcknowledgementReceipt?.acknowledgementReceipt.id) {
        throw new Error('api error')
      }

      const { subscription } = data.createAcknowledgementReceipt;

      navigate('/distributor-confirmation', { state: { subscription }});
    } catch(e) {
      console.log(e)
      setError('api', { type: 'custom', message: 'System error'});
    }
  }

  return (
    <>
      <Transition appear show={isErrorOpen} as={Fragment}>
        <Dialog
          as="div" className="fixed inset-0 bg-black bg-opacity-70 opacity-100 z-[99] m-4"
          onClose={() => setIsErrorOpen(false)}
        >
          <div className="flex h-full items-center justify-center text-[#333]">
            <Dialog.Panel className="w-full max-w-sm rounded bg-white flex flex-col items-center justify-center px-6 py-6">
              <Dialog.Title>{t('serial_number.add_device_title_error')}</Dialog.Title>
              <div className="flex justify-center w-full mt-2 text-sm">
                <button onClick={() => setIsErrorOpen(false)}>{t('common.close')}</button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </Transition>
      <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
        <div className="flex-1">
          <div className="mt-6 mb-8">
            <div className="flex justify-center mb-10">
              <img src="/images/logo.png" alt="KEX Karaoke logo" />
            </div>
            <h1 className="text-center mb-4">{t('distributor.text1')}</h1>
            <LabelField
              label={t('distributor.text2')}
              type="text"
              error={errors.distributionCompany?.message}
              register={register('distributionCompany', { required: t('common.required') })}
            />
            <LabelField
              label={t('distributor.text3')}
              type="text"
              error={errors.invoiceNumber?.message}
              register={register('invoiceNumber', { required: t('common.required') })}
            />
            <LabelField
              label={t('distributor.text4')}
              type="date"
              error={errors.dateOfReceipt?.message}
              register={register('dateOfReceipt', { required: t('common.required') })}
              max={dayjs().format('YYYY-MM-DD')}
            />
            <LabelField
              label={t('distributor.text5')}
              type="text"
              error={errors.serialNumber?.message}
              register={register('serialNumber', { required: t('common.required') })}
            />
          </div>
          <div className="mb-4">
            <div
              className="relative bg-white w-full h-[300px] rounded mb-2"
              onClick={() => {

              }}
            >
              <div
                className="text-[#AAA] font-bold absolute left-4 top-4 cursor-default select-none"
                onClick={() => canvasRef.current.clear()}
              >
                {t('distributor.text9')}
              </div>
              <SignatureCanvas
                ref={canvasRef}
                penColor='black'
                backgroundColor="rgba(255, 255, 255, 0)"
                canvasProps={{
                  className: 'sigCanvas w-full h-full'
                }}
              />
              <div
                className="text-[#56CCF2] font-bold cursor-pointer hover:underline underline-offset-4 absolute left-4 bottom-4"
                onClick={() => canvasRef.current.clear()}
              >
                Clear
              </div>
            </div>
          </div>
          <div className="text-center mb-4">
            <label htmlFor="termsCondition">
              <input
                className="mr-2"
                type="checkbox"
                name="termsCondition"
                id="termsCondition"
                { ...register('termsCondition', { required: t('distributor.text10') }) }
              />
              <span className="mr-2">{t('distributor.text6')}</span>
              <a href="">{t('distributor.text7')}</a>
            </label>
            {
              errors.termsCondition && (
                <div className="text-rose-300 text-sm">{errors.termsCondition.message}</div>
              )
            }
          </div>
          {
            errors.api && (
              <div className="text-rose-300 text-sm mb-4 text-center">{errors.api.message}</div>
            )
          }
          <div className="mb-4">
            <Button
              loading={isSubmitting || loading}
              background="#2D9CDB"
              color="#FEFEFE"
              onClick={() => {
                clearErrors('api');
                handleSubmit(onSubmit)()
              }}
            >
              {t('distributor.text8')}
            </Button>
          </div>
        </div>

      </div>
    </>
  )
}
