import { useEffect } from 'react';
import {
  useSearchParams,
  Route,
  Routes,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { createApolloClient } from './apollo';

import { UserProvider } from 'hooks/useUser';
import { useDevice } from 'hooks/useDevice';
import { CartProvider } from 'hooks/useCart';

import ProtectedRoute from 'components/ProtectedRoute'
import ValidateAuthToken from 'components/ValidateAuthToken';
import ErrorBoundary from 'ErrorBoundary';

import SignIn from './screens/SignIn';
import SignUp from './screens/SignUp';
import Subscription from './screens/Subscription';
import SerialNumber from './screens/SerialNumber';
import CurrentPlan from './screens/CurrentPlan';
import Checkout from './screens/Checkout';
import PaymentSuccess from './screens/PaymentSuccess';
import MySubscriptions from './screens/MySubscriptions';
import CancelSubscription from './screens/CancelSubscription';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import SignOut from './screens/SignOut';
import AcknowledgementReceipt from './screens/AcknowledgementReceipt';
import DistributorConfirmation from './screens/DistributorConfirmation';
import ChangePassword from './screens/ChangePassword';

export default function App() {
  const { setDevice } = useDevice();
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get('serial_number');
  const client = createApolloClient();

  useEffect(() => {
    const device = localStorage.getItem('device');
    if (device) {
      setDevice(device);
    }
  }, [setDevice])

  useEffect(() => {
    if (serialNumber && serialNumber !== 'null') {
      setDevice(serialNumber);
      localStorage.setItem('device', serialNumber);
    }
  }, [serialNumber, setDevice]);

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
        <UserProvider>
          <CartProvider>
            <ApolloProvider client={client}>
              <ValidateAuthToken />
              <Routes>
                <Route path="/" element={<SignIn />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/signout" element={<SignOut />} />
                <Route path="/acknowledgement-receipt" element={<AcknowledgementReceipt />} />
                <Route path="/distributor-confirmation" element={<DistributorConfirmation />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/subscription" element={<Subscription />} />
                  <Route path="/serial-number" element={<SerialNumber />} />
                  <Route path="/current-plan" element={<CurrentPlan />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/payment-success" element={<PaymentSuccess />} />
                  <Route path="/my-subscriptions" element={<MySubscriptions />} />
                  <Route path="/cancel-subscription" element={<CancelSubscription />} />
                </Route>
              </Routes>
            </ApolloProvider>
          </CartProvider>
        </UserProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  )
}
