import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';

export default function PasswordResetCompleted() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1">
        <div className="mt-6 mb-8">
          <div className="flex justify-center mb-10">
            <img src="/images/logo.png" alt="KEX Karaoke logo" />
          </div>
          <div>
            <div className="text-center text-2xl font-bold mb-8">{t('reset_password.text2')}</div>
            <Button
              background="#2D9CDB"
              color="#FEFEFE"
              onClick={() => {
                navigate('/signin')
              }}
            >
              {t('signin.text1')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
