import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';

import ActivatedPlanCard from 'components/ActivatedPlanCard';

export default function SubscriptionPlanHistory({ subscriptions }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  if (!subscriptions.length) {
    return null;
  }

  return (
    <div>
      <div className="mb-8  px-6 py-4 bg-[#4c4e53] text-[#FEFEFE] rounded-md flex justify-between">
        <div>{t('my_subscriptions.text1')}</div>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="cursor-pointer text-xl font-bold"
        >
          &#43;
        </div>
      </div>
      <AnimatePresence initial={false}>
        {
          isOpen && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 }
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              {
                subscriptions.map(subscription => (
                  <ActivatedPlanCard
                    key={subscription.id}
                    product={subscription.product}
                    serialNumber={subscription.device.serialNumber}
                    subscription={subscription}
                    showCollapse={true}
                    deafultIsOpen={false}
                  />
                ))
              }
            </motion.section>
          )
        }
      </AnimatePresence>
    </div>
  )
}
