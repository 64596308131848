import { gql } from '@apollo/client';

export const CREATE_ACKNOWLEDGEMENT_RECEIPT = gql`
  mutation(
    $distributionCompany: String!
    $invoiceNumber: String!
    $dateOfReceipt: Date!
    $serialNumber: String!
    $signature: Upload!
  ) {
    createAcknowledgementReceipt(
      input: {
        distributionCompany: $distributionCompany
        invoiceNumber: $invoiceNumber
        dateOfReceipt: $dateOfReceipt
        serialNumber: $serialNumber
        signature: $signature
      }
    ) {
      acknowledgementReceipt {
        id
      }
      subscription {
        id
        startDate
        endDate
        status
        device {
          serialNumber
        }
        product {
          id
          name
          periodType
          price
        }
      }
      error
    }
  }
`
