import { useTranslation } from 'react-i18next';

export default function SendPasswordResetSuccess() {
  const { t } = useTranslation();

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1">
        <div className="mt-6 mb-8">
          <div className="flex justify-center mb-10">
            <img src="/images/logo.png" alt="KEX Karaoke logo" />
          </div>
          <div>
            <div className="text-center text-2xl font-bold mb-4">{t('forgot_password.text3')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
