import React, { createContext, useContext, useMemo, useState, useEffect } from 'react';

const CartContext = createContext({});

export const CartProvider = props => {
  const { children } = props;
  const [cart, setCart] = useState();

  useEffect(() => {
    const cart = localStorage.getItem('cart');
    if (cart) {
      setCart(JSON.parse(cart));
    }
  }, [])

  const handleCartUpdate = (newCart) => {
    setCart(newCart);
    localStorage.setItem('cart', JSON.stringify(newCart));
  }

  const contextValue = useMemo(() => ({
    cart,
    setCart: handleCartUpdate,
  }), [
    cart,
  ]);

  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => useContext(CartContext);
