import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useGoogleLogin } from '@react-oauth/google';

import LabelField from 'components/LabelField';
import Button from 'components/Button';
import { TOKEN_AUTH, GOOGLE_OAUTH } from 'queries/account';
import { useUser } from 'hooks/useUser';

export default function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login, initialized, user } = useUser();
  const [searchParams] = useSearchParams();
  const [signIn, { loading }] = useMutation(TOKEN_AUTH);
  const [googleOauth] = useMutation(GOOGLE_OAUTH);
  const { register, handleSubmit, setError, clearErrors, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  });
  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      googleOauth({
        variables: {
          accessToken: codeResponse.access_token,
        }
      }).then(result => {
        handleSignin(result.data.googleOauth);
      });
    },
    flow: 'implicit',
  });


  const onSubmit = async (values) => {
    try {
      const result = await signIn({
        variables: {
          username: values.email,
          password: values.password,
        }
      });

      if (!result.data.tokenAuth.token) {
        throw new Error();
      }

      handleSignin(result.data.tokenAuth);
    } catch(e) {
      console.log(e)
      const message = e.message === 'Please enter valid credentials'
        ? t('signin.text5')
        : 'System error'

      setError('api', { type: 'custom', message });
    }
  }

  const handleSignin = async (data) => {
    const { token, user } = data;
    await login({ token, user });
    const redirect = searchParams.get('redirect');

    const redirectPath = redirect || '/serial-number';

    navigate(redirectPath);
  }

  useEffect(() => {
    if (initialized && user) {
      const route = user.role === 'AGENT'
        ? '/acknowledgement-receipt'
        : '/serial-number';

      navigate(route);
    }
  }, [initialized, user]);

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1">
        <div className="mt-6 mb-8">
          <div className="flex justify-center mb-10">
            <img src="/images/logo.png" alt="KEX Karaoke logo" />
          </div>
          <LabelField
            label={t('common.email')}
            type="email"
            hints={t('common.email_hints')}
            error={errors.email?.message}
            register={register('email', { required: t('common.required') })}
          />
          <LabelField
            label={t('common.password')}
            type="password"
            hints={t('common.password_hints')}
            error={errors.password?.message}
            register={register('password', { required: t('common.required') })}
          />
          <div>
            <Link to="/forgot-password">{t('signin.text4')}</Link>
          </div>
        </div>
        {
          errors.api && (
            <div className="text-rose-300 text-sm mb-4">{errors.api.message}</div>
          )
        }
        <div className="mb-4">
          <Button
            loading={isSubmitting || loading}
            background="#2D9CDB"
            color="#FEFEFE"
            onClick={() => {
              clearErrors('api');
              handleSubmit(onSubmit)()
            }}
          >
            {t('signin.text1')}
          </Button>
        </div>
        <div className="text-center">
          <span className="mr-2">{t('signin.text2')}</span>
          <Link to="/signup">{t('signin.text3')}</Link>
        </div>
      </div>
      <div className="pb-20">
        <Button
          background="#FFFFFF"
          color="#333333"
          onClick={googleLogin}
        >
          <div className="flex items-center justify-center">
            <img className="mr-2" src="/images/google.png" alt="Google logo" />
            {t('common.google')}
          </div>
        </Button>
      </div>
    </div>
  )
}
