import { Suspense } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Button from 'components/Button';
import Loading from 'components/Loading';
import { useCart } from 'hooks/useCart';
import { useDevice } from 'hooks/useDevice';
import { GET_PRODUCTS } from 'queries/product';

import ActivatedSubscription from './components/ActiveSubscription';

export default function Subscription() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { device } = useDevice();
  const { data } = useQuery(GET_PRODUCTS, {
    variables: {
      serialNumber: device,
    },
    fetchPolicy: 'network-only',
    skip: !device,
  });
  const { setCart } = useCart();

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <header className="mb-6 flex">
        <Link to="/" className="w-8"><img src="/images/arrow-left.svg" alt="Back" /></Link>
      </header>
      <div className="relative flex-1 flex flex-col justify-center">
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-[linear-gradient(179.99deg,rgba(2,5,15,0.87)_3.99%,rgba(2,5,15,0.37)_17.22%,rgba(2,5,15,0.8)_32%,#02050F_103.04%),url('assets/images/background1.png')] bg-no-repeat z-[-1]" />
        <img className="mb-10" src="/images/logo.png" alt="KEX Karaoke logo" />
        <h1 className="text-center mb-6 text-xl font-bold">{t('subscription.text1')}</h1>
        <div className="text-center mb-6">{t('subscription.text2')}</div>
        <Suspense fallback={<Loading size="xl" />}>
          <ActivatedSubscription />
        </Suspense>
        <div className="grid gap-4 mb-6">
          {
            data?.products.edges.map(({ node }) => (
              <Button
                key={node.id}
                background="#2D9CDB"
                color="#FEFEFE"
                onClick={() => {
                  setCart(node);

                  navigate('/current-plan')
                }}
              >
                {node.name}
              </Button>
            ))
          }
        </div>
        {/* <div className="text-center">{t('subscription.text3')}</div> */}
      </div>
      <div className="mb-20">
        <div className="mb-2">
          <Button onClick={() => navigate('/my-subscriptions')}>{t('subscription.text4')}</Button>
        </div>
      </div>
    </div>
  );
}
