import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';

import LabelField from 'components/LabelField';
import Button from 'components/Button';
import PasswordResetCompleted from 'components/PasswordResetCompleted';
import { RESET_PASSWORD } from 'queries/account';

export default function ResetPassword() {
  const [isCompleted, setIsCompleted] = useState(false);
  const { t } = useTranslation();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { register, handleSubmit, watch, setError, clearErrors, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      password: '',
      resetPassword: '',
    }
  });

  const onSubmit = async (values) => {
    try {
      const result = await resetPassword({
        variables: {
          password: values.password,
          token,
        }
      });

      if (!result.data?.passwordReset?.success) {
        throw new Error();
      }

      setIsCompleted(true);
    } catch(e) {
      console.log(e)
      setError('api', { type: 'custom', message: 'System error'});
    }
  }

  if (isCompleted) {
    return <PasswordResetCompleted />
  }

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1">
        <div className="mt-6 mb-8">
          <div className="flex justify-center mb-10">
            <img src="/images/logo.png" alt="KEX Karaoke logo" />
          </div>
          <LabelField
            label={t('common.password')}
            type="password"
            hints={t('common.password_hints2')}
            error={errors.password?.message}
            register={register('password', { required: t('common.required'), minLength: {
              value: 8,
              message: t('common.password_min_length'),
            } }) }
          />
          <LabelField
            label={t('common.confirm_password')}
            type="password"
            hints={t('common.confirm_password_hints')}
            error={errors.confirmPassword?.message}
            register={register('confirmPassword', {
              required: t('common.confirm_password_required'),
              validate: (val) => {
                if (watch('password') !== val) {
                  return t('common.confirm_password_invalid')
                }
              },
            }) }
          />
        </div>
        {
          errors.api && (
            <div className="text-rose-300 text-sm mb-4">{errors.api.message}</div>
          )
        }
        <div className="mb-4">
          <Button
            loading={isSubmitting || loading}
            background="#2D9CDB"
            color="#FEFEFE"
            onClick={() => {
              clearErrors('api');
              handleSubmit(onSubmit)()
            }}
          >
            {t('reset_password.text1')}
          </Button>
        </div>
      </div>
    </div>
  )
}
